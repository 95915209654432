<template>
  <ModalConfirmAlert
    v-if="dataExport"
    :id="'modalAgregationAlert'"
    :message="msgAlert" />
  <ModalConfirmSubmit
    v-if="!dataExport"
    :id="'modalAgregationConfirm'"
    :message="msgConfirm"
    @yes="confirmAgregation(true)"
    @no="confirmAgregation(false)" />
</template>

<script>
import { mapState, mapActions } from "vuex";
import ModalConfirmAlert from "/src/components/modals/ModalConfirmAlert.vue";
import ModalConfirmSubmit from "/src/components/modals/ModalConfirmSubmit.vue";
import { mixinModal } from "/src/mixins";
import { submitAggregate } from "/src/service/aggregate.js";

export default {
  name: "AggregationPage",
  components: {
    ModalConfirmAlert,
    ModalConfirmSubmit,
  },
  mixins: [mixinModal],
  data() {
    return {
      dataExport: false,
      msgAlert: "",
      msgAlertExport:
        "集計処理を開始しました。\n結果をスプレッドシートに出力中です。",
      msgConfirm: "集計処理を開始します。",
      msgAlertApi: "集計処理が完了しました。",
      msgFail:
        "エラーが発生しました。\nお手数ですがもう一度トライしてください。",
      interval: null,
    };
  },
  computed: {
    ...mapState("aggregation", ["actionExport"]),
  },
  watch: {
    actionExport() {
      this.showModalAgregation();
    },
  },
  created() {
    this.getDataExport();
  },
  beforeUnmount() {
    clearInterval(this.interval);
  },
  methods: {
    ...mapActions("alertMessage", ["showAlert"]),
    ...mapActions("aggregation", ["setStatus"]),
    getDataExport() {
      let isExport = localStorage.getItem("data_exporting");
      if (isExport == "true") {
        this.dataExport = true;
      } else {
        this.dataExport = false;
      }
      this.setStatus(this.dataExport);
      this.getApi(this.dataExport);
    },
    async showModalAgregation() {
      await this.getDataExport();
      if (this.dataExport) {
        this.msgAlert = this.msgAlertExport;
        this.showModal("modalAgregationAlert");
      } else {
        this.showModal("modalAgregationConfirm");
      }
    },
    confirmAgregation(isconfirm) {
      this.dataExport = isconfirm;
      localStorage.setItem("data_exporting", isconfirm);
      this.setStatus(isconfirm);
      if (isconfirm) {
        this.getApi(isconfirm);
        this.showAlert(
          "集計処理を開始しました。結果をスプレッドシートに出力中です。"
        );
      } else {
        this.showAlert("処理を中止しました。");
      }
    },
    async getAggregateTask() {
      // const res = await submitAggregate();
      // if (res.status == 200) {
      //   if (res.data.status == "finished") {
      //     this.msgAlert = this.msgAlertApi;
      //     if (
      //       res.data.response.error ||
      //       res.data.response.message == "Internal server error"
      //     ) {
      //       this.msgAlert = this.msgFail;
      //     }
      //     this.showModal("modalAgregationAlert");
      //     this.stopGetApi();
      //   }
      // } else {
      //   this.msgAlert = this.msgFail;
      //   this.showModal("modalAgregationAlert");
      // }
      let body = {};
      await submitAggregate(body)
        .then((res) => {
          if (res.status == 200) {
            if (res.data.status == "finished") {
              this.msgAlert = this.msgAlertApi;
              if (
                res.data.response.error ||
                res.data.response.message == "Internal server error"
              ) {
                this.msgAlert = this.msgFail;
              }
              this.showModal("modalAgregationAlert");
              this.stopGetApi();
            }
          } else {
            this.msgAlert = this.msgFail;
            this.showModal("modalAgregationAlert");
          }
        })
        .catch(() => {
          this.stopGetApi();
        });
    },
    getApi(isExport) {
      if (isExport && this.interval == null) {
        if (this.dataExport) this.getAggregateTask();
        this.interval = setInterval(() => {
          if (this.dataExport) this.getAggregateTask();
        }, 60000);
      }
    },
    stopGetApi() {
      localStorage.setItem("data_exporting", false);
      this.setStatus(false);
      clearInterval(this.interval);
      this.interval = null;
    },
  },
};
</script>
