import axios from "axios";
import { AGGREGATE_API } from "./const.service.js";

const submitAggregate = async (body) => {
  try {
    const response = await axios.post(AGGREGATE_API, body);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export { submitAggregate };
